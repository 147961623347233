<template>
  <div>
    <BaseDialog
      :max-width="600"
      :propDialog="dialog"
      :title="$i18n.locale === 'vi' || !dish.enName ? dish.viName : dish.enName"
      :topIcon="edit && !dish.priceTBD ? 'plus' : ''"
      @selectTopIcon="openNewOption"
      :actionable="false"
      @closeDialog="setDialog"
    >
      <v-row
        ><v-col
          :cols="edit ? 12 : 6"
          sm="4"
          v-for="(option, index) in sortedOptions"
          :key="index"
          ><v-btn
            :class="edit ? 'd-flex justify-space-between' : ''"
            color="primary"
            block
            :loading="loading(index, selectedIndex)"
            @click.stop="selectOption(option)"
            ><v-icon
              v-if="edit"
              left
              :disabled="!canRemove"
              @click.stop="deleteOption(option, index)"
              >{{ canRemove ? '$delete' : '' }}</v-icon
            >{{ optionPriceDisplay(option)
            }}<v-icon
              v-if="edit"
              right
              :disabled="dish.priceTBD"
              @click.stop="openUpdateOption(option)"
              >{{ dish.priceTBD ? '' : '$edit' }}</v-icon
            ></v-btn
          ></v-col
        >
      </v-row>
    </BaseDialog>
    <DishOptionPrice
      v-if="optionDialog"
      :dialog.sync="optionDialog"
      :dish="dish"
      :option="selectedOption"
      :update="updateOption"
      @updateOptionList="sortOption"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapActions } from 'vuex'
import { displayErrorMessage } from '@/utils'

const DishOptionPrice = () => import('./DishOptionPrice')

export default {
  name: 'DishOptions',

  components: {
    DishOptionPrice
  },

  props: {
    dish: Object,
    dialog: Boolean,
    edit: {
      type: Boolean,
      default: false
    },
    container: {
      validator: function(value) {
        return ['order-dialog', 'invoice-dialog', 'menu'].includes(value)
      },
      default: 'menu'
    }
  },

  data: () => ({
    sortedOptions: null,
    updateOption: null,
    optionDialog: false,
    selectedOption: '',
    selectedIndex: null
  }),

  computed: {
    baseOption() {
      const base = { ...this.dish }
      const optionParams = ['viName', 'enName', 'dishCode']
      for (const key in base) {
        if (!optionParams.some(x => x === key)) {
          delete base[key]
        }
      }
      base.price = null
      return base
    },

    canRemove() {
      return (
        !this.dish.priceTBD &&
        this.sortedOptions &&
        this.sortedOptions.length > 1
      )
    }
  },

  watch: {
    dish: {
      immediate: true,
      handler: function() {
        this.sortOption()
      }
    }
  },

  methods: {
    ...mapActions('order', ['addToBasket']),

    sortOption() {
      this.sortedOptions = cloneDeep(this.dish.options)
      this.sortedOptions.sort((a, b) => a.price - b.price)
      if (this.dish.priceTBD) {
        this.sortedOptions = this.sortedOptions.filter(
          (option, index) => index === 0
        )
      }
    },

    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    selectOption(option) {
      option.inKilogram = this.dish.inKilogram
      option.fromKitchen = this.dish.fromKitchen
      if (this.container === 'invoice-dialog') {
        this.$mitt.emit('addToInvoice', option)
      } else {
        this.addToBasket(option)
      }
      this.$notify(this.$t('order.message-add-to-order'))
      this.setDialog(false)
    },

    optionPriceDisplay(option) {
      if (Number.isFinite(option.price)) {
        return option.price.toLocaleString()
      } else {
        return this.$t('dish.price-TBD')
      }
    },

    openNewOption() {
      this.updateOption = false
      this.selectedOption = this.baseOption
      this.optionDialog = true
    },

    openUpdateOption(option) {
      this.updateOption = true
      this.selectedOption = option
      this.optionDialog = true
    },

    deleteOption(option, index) {
      this.selectedIndex = index
      this.$store
        .dispatch('dish/deleteOption', option._id)
        .then(() => this.sortOption())
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.selectedIndex = null))
    },

    loading(index, selectedIndex) {
      if (index === selectedIndex) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
